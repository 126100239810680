
export default {
  data() {
    return {
      selectedValue: ""
    };
  },
  watch: {
    selectedValue(value) {
      this.$router.push(this.switchLocalePath(value));
    }
  },
  computed: {
    links() {
      return [
        { name: "الرئيسية", to: "/" },
        { name: "الأسعار", to: "/prices" },
        { name: "المميزات", to: "/features" },
        { name: "تطبيق المتجر", to: "/store-app" },
        { name: "المدونة", href: "https://blog.matjrah.com/" },
      ];
    },
  },
  mounted() {
    let referral = this.$route.query.referral;

    if (referral) {
      this.$cookies.set('referral', referral, {
        path: '/',
        maxAge: 60 * 60 * 24 * 60
      })
    }

  },
  created() {
    this.selectedValue = this.$i18n.locale;
  },
  methods: {

  }
};
